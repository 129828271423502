


































































































































































































































































































































































































































































































































































































































































































































































































.faceStudentstatistics {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .mabtm {
    padding-bottom: 0.675rem;
    border-bottom: 1px solid #f5f5f6;
  }
  .searchboxItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    .el-cascader {
      height: 32px !important;
    }
    
  }
}
.abow_dialog{
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.5);
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
.imgBox {
  display: flex;
  border: 1px solid #797979;
  .CertificationBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;
    border-right: 1px solid #797979;
    .certificationTitle {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #797979;
      background: #f2f2f2;
    }
    .certifictionImg {
      padding: 20px;
      .el-image {
        width: 210px;
        height: 230px;
      }
    }
  }
  .CaptureBox {
    flex: 1;
    width: 500px;
    .CaptureTitle {
      height: 3rem;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #797979;
      background: #f2f2f2;
    }
    .CaptureList {
      flex: 1;
      overflow-y: auto;
    }
    .certifictionImg {
      padding: 20px;
      .el-image {
        width: 100%;
        height: 230px;
      }
      p {
        padding: 5px 0;
      }
    }
  }
}
